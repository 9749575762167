<template>
	<div class="single-country">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.district")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button v-if="mayEdit" :text="$t('terms.edit')" @click.native="goTo({name: 'district-edit',params:{id:routeEntity.id}})" />
			</div>
		</div>
		<spinner v-if="isLoading || ready !== true"/>
		<template v-else>
			<div class="row pt-4">
				<div class="col-md-4">
					<form-container>
						<div class="part-label col-12">{{$t("titles.districtDetails")}}</div>
						<field-input :label="$t('labels.name')" class="col-md-12" v-model="routeEntity.name" :disabled='true' />
						<field-input :label="$t('labels.homeAddress')" class="col-md-12" v-model="routeEntity.homeAddress" :disabled='true' />
						<field-datetime :label="$t('labels.allowBookingsFrom')" class="col-md-12" v-model="routeEntity.allowBookingsFrom" :disabled='true' />
						<entity-select label="Country" class="col-md-12" v-model="routeEntity.country" entity="Country" search-property="name" display-property="name" value-property="@id" :disabled='true' />
						<field-input class="col-md-12" :label='$t("labels.machines")' :value='routeEntity.machines.length+ " " + $tc("entities.machine",routeEntity.machines.length)' :disabled='true' />
						<field-input class="col-md-12" :label='$t("labels.pilots")' :value='routeEntity.pilots.length+ " " + $tc("entities.pilot",routeEntity.pilots.length)' :disabled='true' />
						<entity-select v-if="hasGlobalSuperAdmin" :label="$t('labels.company')" class="col-md-12" :value="company" entity="Company" search-property="name" display-property="name" value-property="@id" :disabled="true" />
					</form-container>
				</div>
				<div class="col-md-8">
					<polygon-input class="py-4" v-model="parsedBoundsArray" :readonly='true' />
				</div>
			</div>
			<action-list
				entity="User"
				:title="$tc('listHeaders.driver',2)"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'name',header:$t('listHeaders.name')},
					{name:'email',header:$t('listHeaders.email')},
					{name:'bookings',header:$tc('terms.booking',2)},
					{name:'primaryRole',header:$tc('listHeaders.role',2)},
					{name:'createdAt',header:$t('listHeaders.createdAt')},
				]"
				:staticParameters="{
					districts: routeEntity.id,
					'order[createdAt]': 'asc',
					state_equals: 1
				}"
				:item-link="{
					route: 'user-view',
					parameters: {
						id: 'id'
					}
				}"
				:create="{
					route: 'user-add',
					permissions: [permissions.USER.CREATE]
				}"
				:show-delete="mayDeleteUser"
			>
				<template v-slot:primaryRole="slotProps">
					{{$t('roles.'+slotProps.data.primaryRole)}}
				</template>
				<template v-slot:createdAt="slotProps">
					{{slotProps.data.createdAt | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:bookings="slotProps">
					{{slotProps.data.bookings.length}}
				</template>
			</action-list>
			<action-list
				entity="Booking"
				:title="$t('listHeaders.upcomingBookings')"
				:columns="[
					{name:'id',header:'#ID'},
					{name:'dateAndTimeSlots',header:this.$t('listHeaders.dateAndTimeSlots')},
					{name:'dateAndTimeSlotDrivers',header:this.$t('listHeaders.dateAndTimeSlotDrivers')},
					{name:'casenumber',header:$t('listHeaders.case')},
					{name:'address',header:$t('listHeaders.address')},
					{name:'issuer',header:$t('listHeaders.createdBy')},
					{name:'companyName',header:this.$t('listHeaders.company')},
					{name:'status',header:$t('listHeaders.status')},
				]"
				:staticParameters="{
					explode: 'user:name,bookingSegment:date,company:name,bookingSegment:startHour,bookingSegment:pilot,bookingSegment:endHour',
					district: routeEntity.id,
					'order_by[createdAt]': 'asc'
				}"
				:item-link="{
					route: 'booking-view',
					parameters: {
						id: 'id'
					}
				}"
				:row-state-filters="{
					completed: {property: 'status', value: [2,3], compare: 'in'}
				}"
			>
				<template v-slot:companyName="slotProps">
					{{slotProps.data.company.name}}
				</template>
				<template v-slot:issuer="slotProps">
					{{slotProps.data.issuer.name}}
				</template>
				<template v-slot:status="slotProps">
					{{formatBookingStatus(slotProps.data.status)}}
				</template>
				<template v-slot:dateAndTimeSlots="slotProps">
					<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentTime(segment)}}</p>
				</template>
				<template v-slot:dateAndTimeSlotDrivers="slotProps">
					<p v-for="(segment,index) in slotProps.data.bookingSegments" :key="segment['@id']" class="list-internal-line">{{formatSegmentPilot(segment)}}</p>
				</template>
			</action-list>
			<action-list
				v-if="mayViewCompanies"
				entity="Company"
				:title="$tc('terms.company',2)"
				:columns="[
					{name:'id',header:this.$t('listHeaders.id')},
					{name:'name',header:this.$t('listHeaders.name')},
					{name:'cvr',header:this.$t('listHeaders.cvr')},
					{name:'billingEmail',header:this.$t('listHeaders.email')},
					{name:'users',header:this.$tc('terms.employee',2)},
					{name:'createdAt',header:this.$t('listHeaders.createdAt')},
				]"
				:staticParameters="{
					'bookings.district': routeEntity.id,
				}"
				:item-link="{
					route: 'company-view',
					parameters: {
						id: 'id'
					}
				}"
			>
				<template v-slot:createdAt="slotProps">
					{{slotProps.data.createdAt | moment("DD-MM-YYYY")}}
				</template>
				<template v-slot:users="slotProps">
					{{slotProps.data.users.length}}
				</template>
			</action-list>
<!--			@deprecated Hauling system is deprecated since version 2-->
<!--			<action-list
				entity="HaulingAssignment"
				:title="$tc('listHeaders.haulingAssignment',2)"
				:columns="[
				{name:'id',header:$t('listHeaders.id')},
				{name:'date',header:$t('listHeaders.date')},
				{name:'assignment',header:$tc('listHeaders.assignment')},
				{name:'machine',header:$t('listHeaders.machine')},
				{name:'hauler',header:$t('listHeaders.hauler')},
				{name:'status',header:$t('listHeaders.status')},
			]"
				:staticParameters="{
					explode: 'hauler:name,machine:name',
					'machine.district':routeEntity.id,
					'order[firstTime]': 'asc',
				}"
				:row-state-filters="{
					completed: {property: 'status', value: [3,4], compare: 'in'}
				}"
				:show-custom-buttons="true"
				firstColTdClasses="short"
			>
				<template v-slot:date="slotProps">
					<template v-if="slotProps.data.type !== 0" >{{slotProps.data.fromTime | moment('DD-MM-YYYY')}}</template>
					<br v-if="slotProps.data.type === 1" />
					<template v-if="slotProps.data.type !== 2">{{slotProps.data.toTime | moment('DD-MM-YYYY')}}</template>
				</template>
				<template v-slot:assignment="slotProps">
					<template v-if="slotProps.data.type !== 0">{{$t('terms.pickup')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.fromAddress,time: $moment(slotProps.data.fromTime).format("HH:mm")})}}</template>
					<br v-if="slotProps.data.type === 1" />
					<template v-if="slotProps.data.type !== 2">{{$t('terms.delivery')}}: {{$t('sentences.atTimeAndAddress',{address:slotProps.data.toAddress,time: $moment(slotProps.data.toTime).format("HH:mm")})}}</template>
				</template>
				<template v-slot:hauler="slotProps">
					{{slotProps.data.hauler.name}}
				</template>
				<template v-slot:status="slotProps">
					{{formatAssignmentStatus(slotProps.data.status)}}
				</template>
				<template v-slot:machine="slotProps">
					{{slotProps.data.machine.name}}
				</template>
			</action-list>-->
			<entity-booking-rules-form v-model="ruleset.booking" :default-values="routeEntity.defaultRuleset" :disabled='true' />
			<entity-currency-rules-form v-model="ruleset.currency" :default-values="routeEntity.defaultRuleset" :disabled='true' />

			<!--<standard-button :text="viewingEntity !== false ? 'Leave district view' : 'Log in as district'" @click.native="setViewingEntity"/>-->
		</template>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import isGranted from "@/mixin/isGranted";
	import {mapState, mapMutations, mapGetters} from "vuex";
	import StandardButton from "@/components/buttons/StandardButton";
	import {buildIRI, normalizeRuleValue} from "@/helperFunctions";
	import FieldInput from "@/components/inputs/FieldInput";
	import FormContainer from "@/components/container/FormContainer";
	import SmallButton from "@/components/buttons/SmallButton";
	import GoogleMap from "@/components/maps/GoogleMap";
	import MapPolygon from "@/components/maps/MapPolygon";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import PolygonInput from "@/components/inputs/PolygonInput";
	import EntityBookingRulesForm from "@/components/forms/EntityBookingRulesForm";
	import EntityCurrencyRulesForm from "@/components/forms/EntityCurrencyRulesForm";
	import ActionList from "@/components/list/ActionList"
	import dataConversionMethods from "@/mixin/dataConversionMethods";
	import permissions from "@/permissions";
	import rules from "@/rules";
	import FieldDatetime from "@/components/inputs/FieldDatetime";

	export default {
		name: "SingleDistrict",
		components: {
			FieldDatetime,
			ActionList, StandardButton, Spinner, FieldInput, FormContainer, SmallButton, MapPolygon, PolygonInput, EntitySelect, EntityBookingRulesForm, EntityCurrencyRulesForm},
		mixins: [isGranted,dataConversionMethods],
		data(){
			return {
				isLoading: true,
				ready: false,
				permissions: permissions,
				ruleset: {
					currency: {},
					booking: {}
				},
			}
		},
		computed: {
			...mapState('concrete', {
				districts: 'districts',
				viewingEntity: 'viewingEntity'
			}),
			...mapGetters('concrete',{
				possibleRules: 'possibleRules',
				groupedRules: 'groupedRules',
				normalizedDefaultRuleValues: 'normalizedDefaultRuleValues'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.districts[this.$route.params.id] !== 'undefined') ? this.districts[this.$route.params.id] : false ;
			},
			parsedBoundsArray(){
				return this.routeEntity !== false ? JSON.parse(this.routeEntity.bounds) : [];
			},
			mayDeleteUser(){
				return this.viewingEntity !== false ? this.isGranted(permissions.USER.DELETE,this.viewingEntity) : this.isGranted(permissions.USER.DELETE) ;
			},
			mayEdit(){
				return this.viewingEntity !== false ? this.isGranted(permissions.DISTRICT.UPDATE,this.viewingEntity) : this.isGranted(permissions.DISTRICT.UPDATE) ;
			},
			mayViewCompanies(){
				return this.viewingEntity !== false ? this.isGranted([permissions.COMPANY.READ,permissions.SCREEN.COMPANY],this.viewingEntity) : this.isGranted([permissions.COMPANY.READ,permissions.SCREEN.COMPANY]) ;
			}
		},
		methods: {
			goTo(route){
				if(typeof route.params.lang === 'undefined')
					route.params.lang = this.$i18n.locale
				this.$router.push(route);
			},
			fetchDistrict(){
				this.isLoading = true;
				this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'District'}})
				.then(response => {
					if(typeof this.routeEntity.ruleset !== 'undefined')
					{
						this.possibleRules.forEach((rule) => {
							if(typeof this.routeEntity.ruleset[rule] !== 'undefined' && normalizeRuleValue(rule,this.routeEntity.ruleset[rule]) !== normalizeRuleValue(rule,this.routeEntity.defaultRuleset[rule]))
							{
								this.ruleset[rules[rule].group][rule] = this.routeEntity.ruleset[rule];
							}
						})
					}
					this.ready = true;
				})
				.catch(error =>
				{
					console.log(error);
				})
				.then(() =>
				{
					this.isLoading = false;
				},() =>
				{
					this.isLoading = false;
				})
			}
		},
		mounted()
		{
			this.fetchDistrict();
		},
		watch: {
			$route: {
				deep:true,
				handler: function(){
					this.fetchDistrict();
				}
			}
		}
	};
</script>

<style scoped>

</style>
